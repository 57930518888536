var Teams = {
  init: function () {
    Teams.handleTeamModal();
  },
  handleTeamModal: function () {
    var $ = jQuery.noConflict(),
        $teamsContainer = $('.js-team-members-container'),
        $teamMember = $teamsContainer.find('.team-members');
    $teamMember.on('click', function () {
      var $this = $(this),
          portfolioEntryHTML = $this.find('.js-portfolio-entry').html(),
          // This finds the closest elementor template tad to aget the Elementor->Templates Class that applies styles to the modal content
      elementorTemplateID = $this.find('[data-elementor-type="page"]').attr('data-elementor-id'),
          templateStylingClass = 'elementor-' + elementorTemplateID;
      const fancybox = new Fancybox([{
        src: portfolioEntryHTML,
        type: 'html'
      }], {
        mainClass: 'team-member-modal ' + templateStylingClass,
        template: {
          closeButton: '<i class="icon-close"></i>'
        },
        dragToClose: false
      });
    });
  }
};
export { Teams };