/**
 * Main Site files to include.
 */
import './vendor';
/* SITE/TEMPLATE IMPORTS */
// import Header from './templates/header.js';

import { Teams } from './pages/teams.js';
/* BLOCK/COMPONENT IMPORTS */
// import Accordion from '@components/accordion/_script';

/* REACT COMPONENT/TAGS/HELPERS IMPORTS */
// import HeadingControl from '@tags/heading-control';
// import MediaControl from '@tags/media-control';
// import Wrapper from '@tags/wrapper';
// Import images needed in JS
// import example from '@assets/images/example.png';

/* COMPONENT LAUNCHER
	- takes a query (element Class or Id), and passes all matching Nodes to the given function.
	- takes a function that will be passed the DOM Node of each element that matches the Query critera
*/

const launchComponents = (componentQuery, componentFunction) => {
  document.querySelectorAll(componentQuery).forEach(element => {
    componentFunction(element);
  });
};
/* COMPONENTS
	in order for your '_script' partial for your Component to be launched,
	please do the following:

	1. import your _script
		(e.g import MyComponent from '@components/my-component/_script;')
	2. add a launchComponents call, passing it your imported _script
		and a DOM Query for the Launcher
		(e.g launchComponents('.my-component#my-component-id', MyComponent); )
*/
// launchComponents( '.accordion', Accordion );
// launchComponents( '.header', Header );


jQuery(document).ready(function () {
  Teams.init();
});